/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Platzi from "@components/sharedComponents/customersLogos/logos/Platzi.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Prestamype from "@components/sharedComponents/customersLogos/logos/Prestamype.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import Synlab from "@components/sharedComponents/customersLogos/logos/Synlab.svg"
import Vvoden from "@components/sharedComponents/customersLogos/logos/Vvoden.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import E4CC from "@components/sharedComponents/customersLogos/logos/E4CC.svg"
import Farenet from "@components/sharedComponents/customersLogos/logos/Farenet.svg"

//imagen articulos blog
import article3 from "@components/sharedComponents/articleBlog/images/plataforma-score.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/soluciones-telefonicas-para-empresas.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/software-cloud-pbx.jpg"
import article1 from "@components/sharedComponents/articleBlog/images/estrategias-mejorar-ccenter-colombia.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/estrategias-automatizar-colombia.jpg"

// logos testimonios
import CulqiTes from "@components/sharedComponents/testimonies/images/logos/culqi.svg"
import NetcallTes from "@components/sharedComponents/testimonies/images/logos/netcall.svg"
import SynlabTes from "@components/sharedComponents/testimonies/images/logos/synlab.svg"
import IttsaBusTes from "@components/sharedComponents/testimonies/images/logos/ittsaBus.svg"
import EnseñaTes from "@components/sharedComponents/testimonies/images/logos/enseña.svg"

//foto testimonio desktop
import culqiDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/culqi.png"
import netcallDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/netcall.png"
import synlabDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/synlab.png"
import ittsaBusDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/ittsaBus.png"
import enseñaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/enseña.png"

//foto testimonio desktop
import culqiMobile from "@components/sharedComponents/testimonies/images/fotosMobile/culqi.png"
import netcallMobile from "@components/sharedComponents/testimonies/images/fotosMobile/netcall.png"
import synlabMobile from "@components/sharedComponents/testimonies/images/fotosMobile/synlab.png"
import ittsaBusMobile from "@components/sharedComponents/testimonies/images/fotosMobile/ittsaBus.png"
import enseñaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/enseña.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageColombia/pageContactCenter/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageColombia/pageContactCenter/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageColombia/pageContactCenter/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageColombia/pageContactCenter/images/accordionLeft/imageFour.webp"
import ImageAccordionLeftFive from "@components/pageColombia/pageContactCenter/images/accordionLeft/imageFive.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageContactCenterIA.jpg"

import { TitleUnderlineIntro } from "@components/pageColombia/pageContactCenter/titleUnderline/titleUnderlineAccordionRight"


import imageStartContactCenter from "@components/pageColombia/pageContactCenter/images/imageIntro.webp"
import bandera from "@components/pageColombia/pageWhatsappPrecio/intro/image/🇨🇴.png"


const titleStart = (
  <p>
    Beex es el software para Contact Center con el mejor <TitleUnderlineIntro underline="soporte en" /> <TitleUnderlineIntro underline="Colombia" /> <img src={bandera} alt="" />
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Mejora tus campañas inbound y outbound con un enfoque omnicanal. Automatiza con IVR y chatbots de IA, lanza campañas telefónicas con marcadores inteligentes y optimiza ventas, cobros y servicio.
        <br /><br />
        Supervisa en tiempo real y potencia la productividad de tus agentes. Reduce costos y mejora la eficiencia operativa con el software de Contact Center diseñado para ofrecer la mejor experiencia de usuario.
      </p>
    ),
    image: imageStartContactCenter,
    textbutton: "Solicitar demo",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Platzi,
    Alfin,
    Prestamype,
    Civa,
    Synlab,
    Vvoden,
    Conexo,
    Eriza,
    E4CC,
    Farenet
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Platzi, Alfin, Prestamype, Civa,],
    arrayTwo: [Synlab, Vvoden, Conexo, Eriza, E4CC, Farenet],
  },
  articles: [
    {
      image: article1,
      title: "10 estrategias para mejorar el servicio de los Contact Center en Colombia ",
      textbody:
        "Mejora el servicio de tu Contact Center en Colombia con 10 estrategias efectivas que optimizan la atención al cliente y aumentan la satisfacción.",
      link: "https://beexcc.com/blog/estrategias-mejorar-ccenter-colombia/",
      card: true,
    }, {
      image: article2,
      title: "10 estrategias de automatización para los Contact Center en Colombia",
      textbody:
        "Conoce las 10 estrategias de automatización para optimizar la eficiencia de tu Contact Center en Colombia y mejorar la experiencia del cliente.",
      link: "https://beexcc.com/blog/estrategias-automatizar-colombia/",
      card: true,
    },
    {
      image: article3,
      title: "Beex Contact Center: qué es y cómo funciona",
      textbody:
        "Descubre qué es esta plataforma y cómo puede elevar la productividad y la calidad de tus interacciones con tus clientes.",
      link: "https://beexcc.com/blog/plataforma-score/",
    },
    {
      image: article4,
      title: "10 soluciones telefónicas que debes conocer en el 2024",
      textbody:
        "Conoce 10 soluciones telefónicas para empresas con esta lista de buenas opciones.",
      link: "https://beexcc.com/blog/soluciones-telefonicas-para-empresas/",
    },
    {
      image: article5,
      title: "Top 10 de Software Cloud PBX para empresas en el 2024",
      textbody:
        "Eleva la eficiencia de tu Contact Center con esta selección de los 10 mejores Software Cloud PBX.",
      link: "https://beexcc.com/blog/software-cloud-pbx/",
    },
  ],
  contacts: {
    title: "Potencia tus operaciones de Contact Center con tecnología cloud",
    textbody: "Cuéntanos sobre tus operaciones outbound o inbound para que nuestros asesores te brinden un feedback que te permita mejorar tus servicios.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: CulqiTes,
      title:
        "Con Beex, hemos logrado mejorar la contactabilidad con nuestros leads en +65%",
      textbody:
        "Culqi, la fintech que revoluciona la forma de hacer pagos digitales, usa Beex para automatizar sus procesos de telemarketing, por canal telefónico y WhatsApp.",
      name: "Paul Vera",
      post: "Jefe de Telemarketing",
      link: "/casos-de-exito/culqi/",
      imgDesktop: culqiDesktop,
      imgMobile: culqiMobile,
      metrics: [
        {
          metric: "+65%",
          descripcion: "mejoró su contactabilidad con sus leads",
          color: "#FFB800",
        },
        {
          metric: "x8",
          descripcion: "aumentó sus ventas mensuales",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "redujo el tiempo de recorrido de bases",
          color: "#00CA56",
        },
      ],
    },
    {
      image: NetcallTes,
      title:
        "Con Beex y su marcador predictivo, hemos duplicado nuestras ventas mensuales",
      textbody:
        "Netcall Perú, BPO especializado en brindar soluciones al sector telecomunicaciones, ha automatizado procesos operativos, como backoffice, según sus reglas de negocio.",
      name: "Humberto Chumán",
      post: "Jefe de Proyectos",
      link: "/casos-de-exito/netcall-peru/",
      imgDesktop: netcallDesktop,
      imgMobile: netcallMobile,
      metrics: [
        {
          metric: "+25%",
          descripcion: "aumentó su contactabilidad",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion: "mejoró su tasa de ocupación",
          color: "#5831D8",
        },
        {
          metric: "x2",
          descripcion: "duplicaron sus ventas mensuales",
          color: "#00CA56",
        },
      ],
    },
    {
      image: SynlabTes,
      title:
        "Beex nos ha permitido resolver el 20% de  atenciones sin necesidad de agentes",
      textbody:
        "SYNLAB Perú, operación Peruana del laboratorio clínico más grande de Europa, ha mejorado su tiempo de primera respuesta en 95% gracias a las automatizaciones de Beex.",
      name: "Yesenia Sánchez Rodríguez",
      post: "Jefa de Servicio al Cliente",
      link: "/casos-de-exito/synlab/",
      imgDesktop: synlabDesktop,
      imgMobile: synlabMobile,
      metrics: [
        {
          metric: "95%",
          descripcion: "mejoró su tiempo de primera respuesta",
          color: "#FFB800",
        },
        {
          metric: "20%",
          descripcion: "tickets cerrados sin necesidad de un agente",
          color: "#5831D8",
        },
        {
          metric: "20 seg",
          descripcion: "disminuyeron sus respuestas telefónicas",
          color: "#00CA56",
        },
      ],
    },
    {
      image: IttsaBusTes,
      title:
        "Con Beex, mejoramos en 22% la satisfacción de nuestros usuarios al reagendar sus viajes",
      textbody:
        "IttsaBus, empresa de transporte terrestre, automatiza sus procesos de atención al cliente y marketing con Beex, mejorando sus métricas más importantes de satisfacción.",
      name: "Iris Pérez Medina",
      post: "Coordinadora de Marketing y Servicio al Cliente",
      link: "/casos-de-exito/ittsa-bus/",
      imgDesktop: ittsaBusDesktop,
      imgMobile: ittsaBusMobile,
      metrics: [
        {
          metric: "95%",
          descripcion: "mejoraron su tiempo de primera respuesta",
          color: "#FFB800",
        },
        {
          metric: "22%",
          descripcion: "mejoraron satisfacción en reprogramación de viajes",
          color: "#5831D8",
        },
      ],
    },
    {
      image: EnseñaTes,
      title:
        "Con Beex, hemos aumentado en +50% la contactabilidad con potenciales donantes",
      textbody: "Enseña Perú ha logrado aumentar en +40% la cantidad de donantes a su movimiento social que transforma la educación de los niños más necesitados en el país.",
      name: "Omar García",
      post: "Coordinador del Programa de Suscriptores",
      link: "/casos-de-exito/ensena-peru/",
      imgDesktop: enseñaDesktop,
      imgMobile: enseñaMobile,
      metrics: [
        {
          metric: "40%",
          descripcion: "aumentaron sus donantes",
          color: "#FFB800",
        },
        {
          metric: "+50%",
          descripcion: "mejoró su contactabilidad",
          color: "#5831D8",
        },
      ],
    },
    {
      title: "conoce",
      metrics: [
      ],
    },
  ],
  accordionRight: [
    {
      title: "Enfoque omnicanal",
      description: "<p>Unifica todos tus canales de contacto en un solo lugar. Obtén una vista 360 de las interacciones de tus clientes ya sea por llamada, WhatsApp, correo, SMS, Instagram, webchat y Facebook.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Optimiza con inteligencia artificial",
      description: "<p>Transforma tus interacciones con clientes utilizando IA avanzada: analiza sentimientos y comportamientos en tiempo real, configura enrutamiento inteligente y brinda a agentes y chatbots sugerencias automatizadas para responder con mayor precisión.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Marcadores inteligentes para llamadas efectivas",
      description: "<p>Automatiza tus campañas de llamadas segmentando a tu base de datos por criterios específicos. Deriva solo llamadas contestadas por personas, identifica números inactivos o desconectados y gestiona un segundo intento de manera eficiente.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Integraciones que crecen contigo",
      description: "<p>Conecta tus herramientas empresariales sin límites gracias a nuestra API Cloud. Disfruta de una sincronización bidireccional de datos que asegura operaciones fluidas y escalables para maximizar tu productividad.</p>",
      image: ImageAccordionLeftFour
    },
    {
      title: "Dashboards dinámicos",
      description: "<p>Visualiza el desempeño de tus campañas en tiempo real con paneles de control personalizables. Accede a métricas clave como tiempos de espera y niveles de servicio, y filtra datos según los roles y objetivos de cada usuario para una experiencia más relevante y efectiva.</p>",
      image: ImageAccordionLeftFive
    },
  ],
  bannerVideo: {
    title: "¿Cómo automatizar un Contact Center con Beex?",
    description: "<p>Descubre cómo integrar tecnología para gestionar interacciones en múltiples canales desde un solo lugar. Aprende sobre herramientas que agilizan la atención, optimizan recursos y facilitan la comunicación con tus clientes.  <br /><br />   Entiende cómo implementar soluciones que simplifiquen procesos y mejoren la experiencia en cada punto de contacto. Explora estrategias para transformar tu Contact Center con pasos claros y prácticos. </p>",
    image: imageVideo
  },
}

export default data
